<template>
  <div class="col-sm-8">
    <multiselect
      :id="id"
      v-model="pacienteSelected"
      :options="pacientes"
      placeholder=""
      open-direction="bottom"
      :loading="isLoadingComboPacientes"
      :internal-search="false"
      :allow-empty="allowEmpty"
      label="full_name"
      track-by="id"
      select-label="" deselect-label="X"
      :disabled="havePreCierre"
      @open="obtenerListaPacientes('')"
      @search-change="busquedaAsincronaPacientes"
      @input="searchPatientAndShow"
    >
      <span slot="caret" v-if="!(pacienteSelected===null)"></span>
      <span slot="noResult">Sin Resultados</span>
      <span slot="noOptions">Lista Vacia</span>
      <template slot="singleLabel" slot-scope="props">
        <small>{{props.option.full_name}}</small>
      </template>
      <template slot="option" slot-scope="props">
        <small>{{ props.option.ci }} - {{ props.option.full_name }}</small>
      </template>
      <template slot="afterList">
        <div class="row">
          <a
            v-if="showCargarMas"
            href="javascript:void(0)"
            class="col-sm-12 pl-5 py-1 text-dark font-weight-bold"
            @click="obtenerListaPacientes(null)">
            Cargar mas...
          </a>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  components: {
    Multiselect,
  },
  name: 'comboPaciente',
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    paciente: {},
    havePreCierre: {
      type: Boolean,
      required: true,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pacienteSelected: null,
      pacientes: [],
      filter: null,
      totalRegistro: '',
      temporaPageComboPaciente: 0,
      dataPaciente: '',
      isLoadingComboPacientes: false,
    };
  },
  created() {
    if (this.edit) {
      this.pacienteSelected = this.paciente;
    }
  },
  methods: {
    async obtenerListaPacientes(query) {
      this.isLoadingComboPacientes = true;
      this.filter = query;
      this.temporaPageComboPaciente += 1;
      try {
        const REQUEST = {
          filter_key: this.filter,
          page: 100 * this.temporaPageComboPaciente,
          per_page: 1,
        };
        const RESPONSE = await axios.get('clinic/patient/create/search', { params: REQUEST });
        this.pacientes = RESPONSE.data.data.data;
        this.totalRegistro = RESPONSE.data.data.total;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoadingComboPacientes = false;
      }
    },
    busquedaAsincronaPacientes(query) {
      this.temporaPageComboPaciente = 0;
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => this.obtenerListaPacientes(query), DELAY);
    },
    searchPatientAndShow(paciente) {
      if (paciente === null) {
        const pacient = null;
        this.pacienteSelected = '';
        this.$emit('addPaciente', pacient);
        return;
      }
      axios.get(`/clinic/patient/secure_medic/${paciente.id}`)
        .then((response) => {
          this.dataPaciente = response.data.data;
          const pacient = {
            pacienteSelect: this.pacienteSelected,
            datosPaciente: response.data.data.paciente,
          };
          this.$emit('addPaciente', pacient);
        })
        .catch((error) => {
          const pacient = '';
          this.pacienteSelected = '';
          this.$emit('addPaciente', pacient);
          util.showNotify(error.response.data.message, 'error');
        });
    },
  },
  computed: {
    showCargarMas() {
      const isOkToSearch = this.temporaPageComboPaciente * 100 < this.totalRegistro;
      return this.totalRegistro > 100 && isOkToSearch;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
