<template>
  <b-modal
  :title="titleModal"
  size='custom-lg-max'
    hide-footer
    id="TheModalFisioterapia">
    <div class="row mb-2">
      <div
        :class="isInternation && listaPrecioSeguros.length !==0 ? 'col-sm-3' : 'col-sm-6'">
        <div class="row form-group mb-1" style="margin: auto;">
          <label class="col-form-label">Servicios: </label>
          <multiselect2
          track-by="id"
          label="nombre"
          placeholder="Seleccione un servicio"
          :options="servicios"
          :allow-empty="false"
          :show-labels="false"
          v-model="servicioSelected"
          :loading="loading"
          @input="selectServicio"
          select-label=""
          deselect-label="X">
          <span slot="caret" v-if="servicioSelected"></span>
          <span slot="noResult">Sin Resultados</span>
          <span slot="noOptions">Lista Vacia</span>
          <template slot="option" slot-scope="props">
            <small v-if="props.option.is_insurer_service">
              <b>* {{props.option.nombre}}</b>
            </small>
            <small v-else>
              {{props.option.nombre}}
            </small>
          </template>
          >
          </multiselect2>
        </div>
      </div>
      <template v-if="isInternation" >
        <template v-if="servicioSelected">
          <template v-if="listaPrecioSeguros.length !== 0">
            <div class="col-sm-2" style="margin-left: inherit;">
              <label class="col-form-label" style="color: red">
                Precio Seguros:
              </label>
              <multiselect2
                :options="listaPrecioSeguros"
                @input="actualizarInputPrecioSeguro"
                v-model="precioSeguroSelected"
                placeholder=""
                label="nombre" track-by="id"
                select-label=""
                deselect-label="X">
                <span slot="caret" v-if="!(precioSeguroSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="singleLabel" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
                <template slot="option" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
              >
              </multiselect2>
            </div>
            <div class="col-sm-2" style="margin-left: inherit;">
              <label class="col-form-label" style="user-select: none"><br></label>
              <input type="text"
                class="form-control"
                :value="inputPrecioSeguro | numeral('0,0.00')"
                autocomplete="off"
                disabled
              >
            </div>
          </template>
          <template v-if="listaPrecios.length !== 0
              && precioSeguroSelected === null">
            <div class="col-sm-2" style="margin-left: inherit;">
              <label class="col-form-label">
                Precio Normal:
              </label>
              <multiselect2
                placeholder="Seleccionar"
                :options="listaPrecios"
                @input="actualizarPrecioNormal"
                :allow-empty="false"
                label="descuento" track-by="id"
                select-label=""
                deselect-label="X"
                v-model="precioNormalSelected"
                >
                <span slot="caret" v-if="!(precioNormalSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
              </multiselect2>
            </div>
            <div class="col-sm-2" style="margin-left: inherit;">
              <label class="col-form-label" style="user-select: none"><br></label>
              <input type="text"
                class="form-control"
                :value="precioMontoNormal| numeral('0,0.00')"
                autocomplete="off" disabled>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="col-sm-4">
            <div class="form-group">
                <label class="col-form-label" style="user-select: none"><br></label>
                <div class="row">
                  <div class="col-sm-6 text-right">
                    <label class="col-form-label"><b>Total:</b></label>
                  </div>
                  <div class="col-sm-6 px-0">
                    <input type="text"
                    class="form-control"
                    :value="getSubTotalModal | numeral('0,0.00')"
                    disabled>
                  </div>
                </div>
            </div>
          </div>
        </template>
      </template>
      <div class="col-sm-1">
        <label class="col-form-label" style="user-select: none"><br></label>
        <button class="btn btn-success" type="button" @click="agregarItem">Agregar</button>
      </div>
    </div>
    <div class="row form-group">
      <table class="table table-striped table-bordered table-hover border border-secondary"
        width="100%">
        <thead>
          <tr>
            <th width="10%">Session</th>
            <th width="60%" class="text-center">Descripcion</th>
            <th width="20%" v-if="isInternation" >Precio</th>
            <th width="5%">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in detalleServicios" :key="index">
            <td>
              <div class="form-group">
                <vue-numeric
                  class="form-control"
                  v-model="item.session"
                  thousand-separator=""
                  placeholder=""
                />
              </div>
            </td>
            <td>
              <div class="form-group text-center">
                <input type="text" class="form-control"
                  v-model="item.descripcion">
              </div>
            </td>
            <td v-if="isInternation">
              {{ item.precio }}
            </td>
            <td>
              <div class="form-group">
                <div class="pt-2">
                  <i
                    class="nav-icon i-Close-Window font-weight-bold text-danger"
                    style="cursor: pointer"
                    @click="eliminarItem(index)"
                    title="Eliminar"
                  ></i>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button v-if="isInternation && hasActiveEvolucionMedica"
            type="button"
            class="btn btnBlue mr-2"
            @click="() => { setTipoConsumoProducto(2); addServiciosComplemetarios(); }"
            >
            Prescribir
          </button>
        <button
            class="btn"
            :class="isInternation ? 'btnTurquesa' : 'btn-success'"
            type="button"
            @click="() => { setTipoConsumoProducto(1); addServiciosComplemetarios(); }">
            {{ nombreButton }}
        </button>
    </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect2 from '@/components/util/vue-multiselect2';
import VueNumeric from 'vue-numeric';
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TheModalFisioterapia',
  props: {
    titleModal: {
      required: true,
      default: null,
    },
    isInternation: {
      type: Boolean,
    },
    seguro: {
      required: false,
      default: null,
    },
    moneda: {
      required: false,
    },
    hasActiveEvolucionMedica: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detalleServicios: [],
    };
  },
  methods: {
    cerrarModal() {
      this.$bvModal.hide('TheModalFisioterapia');
    },
    ...mapActions('theModalServicios', [
      'resetDataTheModaServios',
      'getPrecioServicios',
    ]),
    agregarItem() {
      let monto = 0;
      if (this.precioSeguroSelected) {
        monto = this.inputPrecioSeguro;
      } else {
        monto = this.getSubTotalModal;
      }
      const OBJ = {
        id: 0,
        servicio_complementario_id: this.servicioSelected.id,
        estado: true,
        cantidad: 0,
        session: 1,
        numero: this.servicioSelected.numero,
        categoria: this.servicioSelected.categoria,
        nombre: this.servicioSelected.nombre,
        descripcion: this.servicioSelected.nombre,
        numero_interno: this.servicioSelected.numero_interno,
        key: `SC - ${new Date().getTime()}`,
        precio: monto,
      };
      this.detalleServicios.push(OBJ);
      this.servicioSelected = null;
      this.reset();
    },
    eliminarItem(index) {
      this.detalleServicios.splice(index, 1);
    },
    addServiciosComplemetarios() {
      this.$emit('addServiciosComplemetarios', this.detalleServicios);
      this.cerrarModal();
      this.detalleServicios = [];
      this.servicioSelected = null;
    },
    actualizarInputPrecioSeguro() {
      if (this.precioSeguroSelected !== null) {
        this.inputPrecioSeguro = this.precioSeguroSelected.monto;
      } else {
        this.inputPrecioSeguro = 0;
      }
    },
    actualizarPrecioNormal() {
      this.precioMontoNormal = this.precioNormalSelected.monto;
    },
    async selectServicio(item) {
      if (this.isInternation) {
        const DTO = {
          aseguradora_id: this.seguro ? this.seguro.id : null,
          servicio_id: item.tipo_servicio_id,
          tipo_cambio: this.moneda.model.tc,
          tipo_moneda: this.moneda.model.is_principal,
          moneda_id: this.moneda.model.id,
          monedas: this.moneda.multiselect,
        };
        await this.getPrecioServicios(DTO);
      }
      // const PRECIOS_PARSE = JSON.parse(this.servicioSelected.precios);
      // this.servicioSelected.monto = this.precioMontoNormal;
      this.$set(this.servicioSelected, 'monto', this.precioMontoNormal);
    },
    setTipoConsumoProducto(tipo) {
      this.$emit('setTipoConsumoProducto', tipo);// Emitimos el tipo al padre
    },
    reset() {
      this.precioSeguroSelected = null;
      this.precioNormalSelected = null;
      this.precioMontoNormal = 0;
      this.inputPrecioSeguro = 0;
      this.listaPrecioSeguros = [];
      this.listaPrecios = [];
    },
  },
  computed: {
    ...mapFields('theModalServicios', [
      'servicios',
      'servicioSelected',
      'precioSeguroSelected',
      'precioNormalSelected',
      'precioMontoNormal',
      'inputPrecioSeguro',
      'listaPrecios',
      'listaPrecioSeguros',
    ]),
    ...mapState('theModalServicios', [
      'loading',
    ]),
    getSubTotalModal() {
      let subTotalModal = 0.00;
      if (this.servicioSelected) {
        subTotalModal = parseFloat(this.servicioSelected.monto);
      }
      return subTotalModal;
    },
    nombreButton() {
      let nombre = 'Guardar';
      if (this.isInternation) {
        nombre = 'Venta';
      }
      return nombre;
    },
  },
  beforeDestroy() {
    this.resetDataTheModaServios();
  },
  components: {
    Multiselect2,
    VueNumeric,
  },
};
</script>
<style scoped>
.btnBlue {
  color: white;
  background-color: #47404f;
}
.btnTurquesa {
  color: white;
  background-color: #319ba1;
}
</style>
