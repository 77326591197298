<template>
    <div>
      <div class="row">
        <div :class="colClass">
          <button
            :class="['btn', 'px-2', 'py-1', 'd-flex', 'justify-content-center', 'text-center', buttonClass]"
            style="white-space: normal;"
            @click="abrirModal()"
          >
            {{ buttonText }}
          </button>
        </div>
        <div v-if="has_Admision" class="col-sm-3">
          <button class="btn px-2 py-1 btn-secondary" @click="PdfHojaAdmision()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path
                d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2
                2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2
                2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2
                2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2
                2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1
                1 0 0 1 1 1z"
              />
            </svg>
          </button>
        </div>
        <div v-if="has_Admision" class="col-sm-3">
          <button class="btn px-2 py-1 btn-secondary nav-icon i-File-Download font-weight-bold"
          @click="imprimirPdfHojaAdmision()"></button>
        </div>
      </div>
      <b-modal
        id="modal-hoja-admision"
        size="lg"
        :title="tituloModal"
        hide-footer
        ref="modalHojaAdmision"
        centered
        header-class="bg-gray-300"
        >
        <div class="container-fluid">
            <div class="row align-items-center mb-3">
              <div class="col-sm-2 text-right">
                <button class="btn btn-secondary">
                  Familiar
                </button>
              </div>
              <div v-if="familiarPaciente || !isEdit" class="col-sm-10">
                <multiselect
                v-model="familiarSelect"
                :options="familiar"
                label="full_name"
                placeholder="Seleccione un familiar"
                track-by="id"
                :searchable="true"
                />
              </div>
              <div v-else>
                <label for="" style="color: red;"> No tiene un Familiar Asignado</label>
              </div>
            </div>
            <div v-if="familiarPaciente || !isEdit" class="row justify-content-end">
              <div class="col-auto">
                <button class="btn btn-success" @click="guardarFamiliar()">
                  Guardar
                </button>
              </div>
            </div>
          </div>
      </b-modal>
    </div>
  </template>
<script>
import { util } from '@/plugins/util';
import { mapActions, mapState } from 'vuex';
import axios from '@/modules/common/axiosERP';
import multiselect from '@/components/util/vue-multiselect2';

export default {
  name: 'ModalHojaAdmision',
  components: {
    multiselect,
  },
  props: {
    isEdit: {
      type: Boolean,
    },
    has_Admision: {
      type: Boolean,
    },
    paciente_id: {
      type: Number,
    },
    tratamiento_id: {
      type: [Number, String],
      require: true,
    },
    familiarPaciente: {
      type: Object,
    },
  },
  data() {
    return {
      familiarSelect: null,
    };
  },
  created() {
    if (this.isEdit) {
      this.cargar();
      this.buscarFamiliar();
    }
  },
  methods: {
    async cargar() {
      await this.getFamiliares(this.paciente_id);
    },
    async abrirModal() {
      if (this.paciente_id) {
        this.cargar();
        if (this.isEdit) {
          this.buscarFamiliar();
        }
        this.$refs.modalHojaAdmision.show();
      } else {
        util.showNotify('Seleccione un paciente', 'warn');
      }
    },
    buscarFamiliar() {
      if (this.familiarPaciente) {
        if (this.familiarPaciente.familiar_id) {
          const familiarEncontrado = this.familiar.find(
            (familiar) => familiar.id === this.familiarPaciente.familiar_id,
          );
          if (familiarEncontrado) {
            this.familiarSelect = familiarEncontrado;
          }
        }
      }
    },
    async imprimirPdfHojaAdmision() {
      const TRATAMIENTO_ID = this.tratamiento_id;
      const params = {
        familiar_id: this.familiarSelect.id, // Aquí se incluye el id del familiar
      };
      try {
        const RESPONSE = await axios.get(`/clinic/tratamientos/hoja_admision/print/${TRATAMIENTO_ID}`, {
          params,
          responseType: 'blob',
        });
        console.log('error', RESPONSE);
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async PdfHojaAdmision() {
      const TRATAMIENTO_ID = this.tratamiento_id;
      const params = {
        familiar_id: this.familiarSelect?.id || null, // Asegurarse de que familiarSelect no sea null
      };

      try {
        const response = await axios.get(`/clinic/tratamientos/hoja_admision/print/${TRATAMIENTO_ID}`, {
          params,
          responseType: 'blob',
        });

        if (response && response.data) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(blob);

          const iframe = document.createElement('iframe');
          iframe.src = pdfUrl;
          iframe.style.display = 'none';
          document.body.appendChild(iframe);

          // Esperar a que se cargue el PDF en el iframe antes de imprimir
          iframe.onload = function () {
            iframe.contentWindow.print();
          };
        } else {
          util.showNotify('La respuesta no contiene datos válidos', 'error');
        }
      } catch (error) {
        console.error('Error al descargar el PDF:', error);
        util.showNotify('Hubo un problema al obtener el PDF', 'error');
      }
    },
    guardarFamiliar() {
      if (this.isEdit) {
        this.$emit('familiar-seleccionado', this.familiarSelect || null);
        this.$bvModal.hide('modal-hoja-admision');
      } else if (this.familiarSelect) {
        this.$emit('familiar-seleccionado', this.familiarSelect);
        this.$bvModal.hide('modal-hoja-admision');
      } else {
        util.showNotify('Seleccione un Familiar para guardar', 'warn');
      }
    },
    ...mapActions('modalHojaAdmision', [
      'getFamiliares',
    ]),
  },
  computed: {
    ...mapState('modalHojaAdmision', [
      'familiar',
    ]),
    tituloModal() {
      return !this.isEdit ? 'Crear Hoja de Admision' : 'Editar Hoja de Admision';
    },
    buttonClass() {
      return this.has_Admision ? 'btn-success' : 'btn-secondary';
    },
    buttonText() {
      return this.has_Admision ? 'Admisión' : 'Hoja de Admisión';
    },
    colClass() {
      return this.has_Admision ? 'col-sm-6' : 'col-sm-12';
    },
    selectedFamiliar() {
      if (this.isEdit && this.familiarPaciente) {
        const familiarEncontrado = this.familiar.find(
          (familiar) => familiar.id === this.familiarPaciente.familiar_id,
        );
        return familiarEncontrado || null;
      }
      return null;
    },
  },
  watch: {
    selectedFamiliar(newValue) {
      this.familiarSelect = newValue;
    },
  },
};
</script>

<style >
@media print {
  @page {
    size: letter;
    margin: 1in;
  }
}
</style>
