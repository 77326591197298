<template>
    <b-modal id="ModalAutorizacionInternacion"
      size="custom-lg-max"
      title="Autorizacion Internacion"
      @ok="guardarModal"
      @hidden="verificarModal"
      @cancel="cancelarGuardado"
    >
      <template #modal-header="{ close }">
        <div class="container row">
          <div class="col-sm-6 pl-0">
            <h5 class="modal-title">Datos de "Autorizacion de Internacion"</h5>
          </div>
        </div>
        <button
        type="button"
        aria-label="Close"
        class="close"
        @click="close()"
      >×</button>

      </template>
      <template>
        <div class="container">
        <div class="row">
          <label for="aviso"> Aqui se llena los datos del garante o familiar que autoriza la atencion de servicios y medicamentos</label>
         </div>
         <div class="row mb-2 mt-3">
          <div class="col-sm-2 text-center">
            <button class="btn btn-secondary  w-100">
              Sistema
            </button>
          </div>
          <div class="col-sm-6">
            <input
              id="inputNombreFamiliar"
              type="text"
              class="form-control"
              placeholder="Nombre Familiar"
            >
          </div>
          <div class="col-sm-2">
            <input
              id="inputCi"
              type="text"
              class="form-control"
              placeholder="CI"
            >
          </div>
          <div class="col-sm-2">
            <input
              id="inputParestesco"
              type="text"
              class="form-control"
              placeholder="Parentesco"
            >
          </div>
         </div>
         <div class="row">
          <div class="col-sm-2 text-center">
            <button class="btn btn-secondary w-100">
              Manual
            </button>
          </div>
          <div class="col-sm-6">
            <input
              id="inputNombreFamiliar"
              type="text"
              class="form-control"
              placeholder="Nombre Familiar"
            >
          </div>
          <div class="col-sm-2">
            <input
              id="inputCi"
              type="text"
              class="form-control"
              placeholder="CI"
            >
          </div>
          <div class="col-sm-2">
            <input
              id="inputParentesco"
              type="text"
              class="form-control"
              placeholder="Parentesco"
            >
          </div>
         </div>
        </div>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <button class="btn btn-outline-dark" @click="cancel()">
          Cancelar
        </button>
        <button class="btn bg-abierto text-white" @click="ok()">
          Guardar
        </button>
      </template>
    </b-modal>
  </template>
<script>

export default {
  name: 'ModalAutorizacionInternacion',

  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    guardarModal() {
      this.store = true;
    },
    cancelarGuardado() {
      this.store = false;
    },
    verificarModal() {
    },
    resetCheckInput() {
    },
  },
};
</script>

<style scoped>
.bg-abierto {
  background: #f8cb9e;
}
</style>
