<template>
  <b-modal
    id="modal-indicacionMedica"
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
    class="modal-no-header"
  >
  <template #modal-title>
    <div class="col">
      <div class="row">
        <h5><b>Indicacion</b></h5>
      </div>
      <div class="row">
        <h6><b>Dr.</b> Maria rodriguez</h6>
      </div>
    </div>
  </template>
  <b-tabs class="nav-tabs custom-tabs">
    <b-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :title="tab.title === 'Recetas' ? nombreFarmacia : tab.title"
        :active="tab.active"
        :disabled="tab.disabled"
        >
        <div v-if="index === 0">
          <div class="row" v-if="indicacionMedica">
            <div class="col-sm-12">
              <textarea
                v-text="indicacionMedica.indicacion_medico"
                disabled
                class="form-control"
                rows="8"
              ></textarea>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12">
              <b>Descripción de enfermera:</b>
            </div>
            <div class="col-sm-12">
              <textarea
                v-model="indicacionEnfermera"
                class="form-control"
                rows="10"
                :disabled="isInternacion"
              ></textarea>
            </div>
          </div>
        </div>
        <div v-if="index === 1">
          <b-table striped hover :items="recetas"
          thead-class="thead-custom"
          >
            <template #cell(farmacia)="data">
              <span :class="getColorClass(data.value)">{{ data.value }}</span>
            </template>
          </b-table>
        </div>
        <div v-if="index === 2">
          <b-table striped hover :items="preEscribirs"
          thead-class="thead-custom"
          >
            <template #cell(estado)="data">
              <span :class="getColorClass(data.value)">{{ data.value }}</span>
            </template>
          </b-table>
        </div>
      </b-tab>
    </b-tabs>

    <template #modal-footer="{ cancel }">
      <button  class="btn btn-light" @click="cancel()">
        <b>Cancelar</b>
      </button>
      <button v-if="!isInternacion" class="btn btn-success" @click="guardarModal(false)">
        <b>Guardar</b>
      </button>
      <button v-if="!isInternacion" class="btn btn-success" @click="guardarModal(true)">
        <b>Guardar Cerrar</b>
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TheModalMedicalIndication',
  props: {
    isInternacion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: [
        {
          title: 'Indicacion Medica', active: true, disabled: false,
        },
        {
          title: 'Recetas', active: false, disabled: false,
        },
        {
          title: 'Servicios', active: false, disabled: false,
        },
      ],
    };
  },
  methods: {
    getColorClass(value) {
      switch (value) {
        case 'Pendiente':
          return 'bg-red';
        case 'Preparado':
          return 'bg-yellow';
        case 'Entregado':
          return 'bg-green';
        default:
          return '';
      }
    },
    async guardarModal(item) {
      if (!this.isInternacion) {
        const { params } = this.$route;
        const tratamientoId = atob(params.id);
        const dto = {
          cierre: item,
          tratamiento_id: tratamientoId,
        };
        const RESULT_OK = await this.updateIndicacion(dto);
        if (RESULT_OK) {
          const PARAMS_INDEX = {
            id: atob(params.id),
            paciente_id: atob(params.paciente_id),
          };
          await this.getDatoInicial(PARAMS_INDEX);
          this.$bvModal.hide('modal-indicacionMedica');
        }
      } else {
        this.$bvModal.hide('modal-indicacionMedica');
      }
    },
    ...mapActions('theModalMedicalIndication', [
      'updateIndicacion',
      'getStaticData',
    ]),
    ...mapActions('atencionEnfermeria', [
      'getDatoInicial',
    ]),
  },
  computed: {
    nombreFarmacia() {
      let nombre = null;
      if (this.nombreParametroCatFarmacia) {
        nombre = this.nombreParametroCatFarmacia.nombre;
      }
      return nombre;
    },
    ...mapState('theModalMedicalIndication', [
      'recetas',
      'indicacionMedica',
      'preEscribirs',
      'nombreParametroCatFarmacia',
    ]),
    ...mapFields('theModalMedicalIndication', [
      'indicacionEnfermera',
    ]),
  },
};
</script>
<style>
.thead-custom {
  background-color: rgb(130, 130, 130);
  color: white;
}
.custom-tabs .nav-tabs .nav-item .nav-link.active {
  background-color: orange;
  color: white !important;
}
.bg-red {
  color: red;
}

.bg-yellow {
  color: yellow;
}

.bg-green {
  color: green;
}
</style>
