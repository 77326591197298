<template>
  <div
    class="cursor-pointer col-sm-2 px-0 mr-3 my-2 border rounded box-size"
    :class="getClassButton"
  >
    <span
      v-if="modeAddCirugia"
      class="text-gray h1 pt-2"
    >+</span>
    <b
      v-else
      class="text-gray"
    >
      Cirugia {{indexCirugia}}
    </b>
  </div>
</template>

<script>
export default {
  name: 'QuirofanoItem',
  props: {
    indexCirugia: {
      type: Number,
      default: 0,
    },
    modeAddCirugia: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClassButton() {
      if (this.modeAddCirugia) {
        return '';
      }
      return 'custom-bg-gray';
    },
  },
};
</script>

<style scoped>
.box-size:active{
  background-color: #c3bfbf;
  box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.5);
}
.custom-bg-gray {
  background: #eee;
}
.text-gray {
  color:#858189;
}
.box-size {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
