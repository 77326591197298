<template>
  <div class="col-sm-12">
    <multiselect
      :id="id"
      v-model="diagnosticoSelected"
      :options="diagnosticos"
      open-direction="bottom"
      :loading="isLoadingComboDiagnosticos"
      :internal-search="false"
      :allow-empty="allowEmpty"
      label="sub_nombre"
      track-by="id_sub"
      :multiple="false"
      select-label="" deselect-label="X"
      placeholder="Seleccione un item"
      @search-change="busquedaAsincronaDiagnosticos"
      @input="searchDiagnosticoAndShow"
      class="custom-multiselect"
    >
      <span slot="caret" v-if="!(diagnosticoSelected === null)"></span>
      <span slot="noResult">Sin Resultados</span>
      <span slot="noOptions">Lista Vacía</span>
      <template slot="option" slot-scope="props">
        <span class="option-text">{{ props.option.sub_codigo }}  - {{ props.option.sub_nombre }}</span>
      </template>

    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  name: 'comboDiagnostic',
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    diagnosticos: {
      type: Array,
      required: true,
      default: () => [],
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    diagnosticoInicial: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      diagnosticoSelected: this.diagnosticoInicial,
      isLoadingComboDiagnosticos: false,
    };
  },
  methods: {
    busquedaAsincronaDiagnosticos(query) {
      this.$emit('search', query); // Emitimos un evento para que el padre maneje la búsqueda
    },
    searchDiagnosticoAndShow(diagnostico) {
      if (diagnostico === null) {
        this.diagnosticoSelected = null;
        this.$emit('addDiagnostico', null);
        return;
      }
      this.$emit('addDiagnostico', diagnostico);
    },
  },
  watch: {
    diagnosticoInicial: {
      handler(val) {
        this.diagnosticoSelected = val;
      },
      immediate: true,
    },
  },
};
</script>

<style>
.custom-multiselect .multiselect__single {
  font-size: 14px;
}

.custom-multiselect .multiselect__option {
  font-size: 14px;
}

.custom-multiselect .multiselect__input {
  font-size: 14px;
}

</style>
