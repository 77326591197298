<template>
  <div style="display: inline">
    <button
    class="pr-3"
    :class="activateButton ? 'btn btn-success' : 'btn btn-secondary'"
    @click="showModalEvent"
    :title="havePreCierre ? messagePreCierre : ''"
    type="button">Servicios (Int.)</button>
    <b-modal v-model="showModal"
    size="lg"
    title="Seleccionar Servicio"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close>
    <div class="row">
      <div class="col-sm-4">
          <div class="form-group row">
            <div class="col-sm-12">
                <label for="moneda_id"
                class="col-form-label text-right" >Medico:</label >
            </div>
            <div class="col-sm-12">
              <multiselect2
                v-model="medicoModel"
                track-by="nombre"
                label="nombre"
                placeholder="Seleccione"
                :options="medicos"
                :searchable="true"
                @select="getServices">
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.cantidad_honorario_servicio > 0"
                    style="color:green;">{{props.option.nombre}}</span>
                  <span v-else>{{props.option.nombre}}</span>
                </template>
              </multiselect2>
            </div>
          </div>
      </div>
      <div class="col-sm-4">
          <div class="form-group row">
            <div class="col-sm-12">
                <label for="moneda_id"
                class="col-form-label text-right" >Servicios:</label >
            </div>
            <div class="col-sm-12">
              <multiselect2
                v-model="servicioModel"
                track-by="id"
                label="tipo_servicio_nombre"
                placeholder="Seleccione"
                :options="servicios"
                :searchable="true"
                :allow-empty="false"
                :loading="loading"
                @select="cargarImporte"
                >
                <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.tipo_servicio_nombre }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.tipo_servicio_nombre }}
                  <span v-if="props.option.categoria_medico_id"
                    style="color: red; font-weight: bold">
                    - {{props.option.categoria_medico_nombre }}
                  </span>
                </template>
              </multiselect2>
            </div>
          </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group row">
          <div class="col-sm-12">
              <label for="moneda_id"
              class="col-form-label text-right" >Importe:</label >
          </div>
          <div class="col-sm-5">
            <vue-numeric class="form-control"
              v-model="importe"
              :disabled="disable"
              separator=","
              :precision="2"
              placeholder=""/>
          </div>
          <div class="col-sm-7">
              <label><input type="checkbox"
              v-model="checkImporte"
              @click="habilitarImporte"
              > Abierto
              </label>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="form-group row">
          <div class="col-sm-12">
              <div class="col-sm-12">
                <label for="moneda_id"
                  class="col-form-label text-right" >Glosa:</label >
              </div>
              <div class="col-sm-12">
                <b-form-textarea
                  id="glosa"
                  class="form-control no-resize"
                  v-model="glosa"
                  placeholder="Ingrese alguna descripcion."
                  rows="3"
                  max-rows="4"
                  style="overflow-y: auto;"/>
              </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group row mt-4">
          <div class="col-sm" v-for="(gloss, index) in opcionGlosas"
            v-bind:item="gloss"
            v-bind:index="index"
            v-bind:key="index">
            <span @click="agregarTextoAGlosa(gloss)" style="cursor: pointer"
            class="badge badge-pill badge-primary ml-2 p-1">
                {{ gloss.nombre }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-right pr-4">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          @click="showModal = false">
          Cerrar
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click="agregarDataServicios">
          Agregar
        </button>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import Multiselect2 from '@/components/util/vue-multiselect2';
import VueNumeric from 'vue-numeric';
import { mapState } from 'vuex';
import { util } from '../../../plugins/util';
import axios from '../../common/axiosERP';

export default {
  name: 'TheModalServicioInterno',
  props: {
    medic: {
      type: Array,
      required: true,
      default: Array,
    },
    sugerencia_glosas: {
      type: Array,
      required: false,
      default: Array,
    },
    servicios_internos: {
      type: Array,
      requerired: true,
      default: Array,
    },
    empresa_moneda: {
      type: Array,
      default: Array,
    },
    havePreCierre: {
      type: Boolean,
      required: true,
      default: false,
    },
    activateButton: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      medicos: this.medic,
      medicoModel: null,
      showModal: false,
      servicios: this.servicios_internos,
      servicioModel: null,
      importe: 0,
      glosa: null,
      checkImporte: false,
      disable: true,
      opcionGlosas: this.sugerencia_glosas,
      fecha: moment(new Date()).format('DD/MM/YYYY'),
      loading: false,
      load: false,
      messagePreCierre: 'Acción no permita usted tiene un precierre',
      honorarios: [],
      honorario: null,
      tipo_servicio_id: null,
    };
  },
  methods: {
    showModalEvent() {
      const modalBloqueado = false;
      if (modalBloqueado) {
        return;
      }
      this.restaurar();
      this.showModal = true;
      this.$emit('activateTheButton', 'servicio_interno');
    },
    restaurar() {
      this.medicoModel = null;
      this.importe = 0;
      this.glosa = null;
      this.servicioModel = null;
      this.checkImporte = false;
      this.disable = true;
      this.servicios = this.servicios_internos;
      this.tipo_servicio_id = null;
      this.honorario = null;
    },
    getServices(medico) {
      if (medico) {
        this.serviceMedic(medico);
      } else {
        this.servicios = this.servicios_internos;
      }
    },
    async serviceMedic(medico) {
      this.loading = true;
      this.servicioModel = null;
      try {
        const params = {
          medico_id: medico.id,
        };
        const response = await axios.get('/clinic/doctors/medico/service', { params });
        this.servicios = response.data.data.honorario_medico;
        this.honorarios = response.data.data.honorario_medico;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    habilitarImporte() {
      if (!this.checkImporte) {
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    dividirCadena() {
      const records = [];
      if (this.glosa) {
        const separador = ',';
        const arrayDeCadenas = this.glosa.split(separador);
        arrayDeCadenas.forEach((element) => {
          const objet = {};
          objet.nombre = element;
          records.push(objet);
        });
      }
      return records;
    },
    agregarDataServicios() {
      if (this.validar()) {
        const data = {
          servicios: this.dataServicios(),
        };
        this.$emit('addServiciosInternos', data);
        this.restaurar();
      }
    },
    agregarTextoAGlosa(item) {
      if (this.glosa) {
        const texto = this.glosa;
        this.glosa = `${texto} , ${item.nombre}`;
      } else {
        this.glosa = item.nombre;
      }
    },
    dataServicios() {
      const data = {
        customId: new Date().getTime(),
        usuario: this.user.name,
        textoglosa: this.glosa,
        id: null,
        numero: '-',
        // venta_servicio_medico_id: (this.medicoModel) ? this.medicoModel.id : '',
        honorario_medico_id: this.honorario ? this.honorario.id : null,
        tipo_servicio_id: this.tipo_servicio_id ? this.tipo_servicio_id : null,
        abierto: this.checkImporte,
        importe: this.importe,
        venta_servicio_fecha: this.fecha,
        fecha: this.fecha,
        venta_servicio_cantidad: 1,
        tipo_servicio_nombre: `${this.servicioModel.tipo_servicio_nombre}`,
        glosas: this.dividirCadena(),
        venta_servicio_estado: true,
        total: this.importe,
        hora: moment().format('HH:mm:ss'),
      };
      return data;
    },
    customLabelServicios({ tipoServicioNombre, categoriaMedicoNombre }) {
      return `${tipoServicioNombre} - ${categoriaMedicoNombre}`;
    },
    cargarImporte(servicio) {
      if (servicio.moneda_id) {
        const moneda = this.empresa_moneda.find(
          (el) => el.id === servicio.moneda_id,
        );
        if (moneda.is_principal === 0) {
          this.importe = servicio.precio_paciente * parseFloat(moneda.tc);
        } else {
          this.importe = servicio.precio_paciente;
        }
      } else {
        this.importe = servicio.precio_paciente;
      }
    },
    showModalEventEdit(dataServicio) {
      this.restaurar();
      this.showModal = true;
      this.loadDataModal(dataServicio);
    },
    loadDataModal(data) {
      this.importe = data.importe;
      this.medicoModel = this.medicos.find((ele) => (
        ele.id === data.venta_servicio_medico_id));
      // mostrar la glosa al editar
      data.glosas.forEach((ele, index) => {
        if (ele.nombre != null) {
          if (index === 0) {
            this.glosa = ele.nombre;
          } else {
            this.glosa = `${this.glosa} , ${ele.nombre}`;
          }
        }
      });
      // obtener servicios al editar
      this.servicios.forEach((element) => {
        if (element.id === data.honorario_medico_id) {
          this.servicioModel = element;
        }
      });
    },
    validar() {
      let valido = true;
      if (!this.servicioModel) {
        util.showNotify('Seleccione un servicio', 'error');
        valido = false;
      }
      if (this.importe <= 0 || this.importe === undefined) {
        util.showNotify('El importe debe ser mayor a 0', 'error');
        valido = false;
      }
      return valido;
    },
  },
  computed: {
    ...mapState('main', [
      'user',
    ]),

  },
  watch: {
    medicoModel(new1) {
      if (!new1) {
        this.servicios = this.servicios_internos;
      }
    },
    servicioModel(data) {
      if (this.medicoModel) {
        this.honorario = this.honorarios.find((e) => e.medico_id === this.medicoModel.id);
      }
      if (data) {
        if (data.id !== undefined && data.id !== null) {
          this.tipo_servicio_id = data.id;
        }
        if (data.tipo_servicio_id !== undefined && data.tipo_servicio_id !== null) {
          this.tipo_servicio_id = data.tipo_servicio_id;
        }
      }
    },
  },
  components: {
    Multiselect2,
    VueNumeric,
  },
};
</script>
