<template>
  <b-modal
    id="TheModalEvolucionMedica"
    size="lg"
  >
    <template #modal-title>
      <b>Evolución Médica:</b>
    </template>
    <div class="row mb-3">
      <div class="col-sm-12">
        <b>Descripción:</b>
      </div>
      <div class="col-sm-12">
        <textarea
          class="form-control"
          v-model="descripcionEvolucionMedica"
          rows="7"
        ></textarea>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-12">
        <b>Indicación (Enfermería):</b>
      </div>
      <div class="col-sm-12">
        <textarea
          class="form-control"
          rows="7"
          v-model="indicacionMedica"
        ></textarea>
      </div>
    </div>
    <!--  <div class="row">
      <div class="offset-sm-8 col-sm-2 text-right">
        <label for="arancel_bs" class="col-form-label">Arancel Bs.:</label>
      </div>
      <div class="col-sm-2 pl-0">
        <vue-numeric
          id="arancel_bs"
          class="form-control text-right"
          v-model="arancel"
          separator=","
          :precision="2"
          placeholder=""
        />
      </div>
    </div> -->
    <template #modal-footer="{ cancel }">
      <button class="btn btn-light" @click="cancel()">
        Cancelar
      </button>
      <button class="btn btn-success" @click="addItem()">
        Guardar
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'TheModalEvolucionMedica',
  components: {
  },
  data() {
    return {
      arancel: 0,
      descripcionEvolucionMedica: null,
      indicacionMedica: null,
    };
  },
  methods: {
    addItem() {
      const DTO = {
        descripcion: this.descripcionEvolucionMedica,
        indicacion: this.indicacionMedica,
        arancel: this.arancel,
        fecha: moment(new Date()).format('DD/MM/YYYY'),
        hora: moment().format('HH:mm:ss'),
        userId: this.user.id,
        userName: this.user.name,
        id: 0,
        estado: true,
        key: `EM - ${new Date().getTime()}`,
      };
      this.$emit('addEvolucionMedica', DTO);
      this.$bvModal.hide('TheModalEvolucionMedica');
      this.reset();
    },
    reset() {
      this.descripcionEvolucionMedica = null;
      this.indicacionMedica = null;
      this.arancel = 0;
    },
  },
  computed: {
    ...mapState('main', [
      'user',
    ]),
  },
};
</script>
