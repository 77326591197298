<template>
<div style="display: inline">
  <button
    :class="cierreData.estado ? 'btn btn-success' : 'btn btn-danger'"
    @click="openModalCierre"
    v-show="preCierre"
  > Liquidacion</button>
  <b-modal id="modal-cierre"
    v-model="shoModalCierre"
    size="lg"
    title="Cerrar Internacion"
    hide-footer
  >
    <div class="row mb-2">
      <div class="col-sm-3">
        <div class="form-group">
          <label></label>
          <div class="col-sm-12">
            <button class="btn btn-success btn-block">En PreLiquidacion</button>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-2 pr-0">
        <div class="form-group">
          <label>Total:</label>
          <vue-numeric
            :disabled="true"
            v-model="total"
            class="form-control"
            separator=","
            :precision="2"
            placeholder=""
          />
        </div>
      </div>
      <div class="col-sm-2 pr-0">
        <div class="form-group">
          <label>Anticipos:</label>
          <vue-numeric
            :disabled="true"
            v-model="anticipos"
            class="form-control"
            separator=","
            :precision="2"
            placeholder=""
          />
        </div>
      </div>
      <div class="col-sm-2 pr-0">
        <div class="form-group">
          <label>Saldo:</label>
          <vue-numeric
            v-model="saldos"
            class="form-control"
            separator=","
            :precision="2"
            placeholder=""
            :minus="true"
          />
          <!-- <input type="text" class="form-control" v-model="saldos"
            :disabled="true"> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 pr-2">
        <div class="form-group mb-0">
          <label class="col-form-label">Glosa Internacion:</label>
          <textarea
            :disabled="true"
            class="form-control"
            placeholder=""
            rows="3"
            v-model="glosaPreCierre"
          ></textarea>
          <label class="col-form-label pb-0" v-if="preCierreData.estado_precierre">
            Por: {{ usuario }} F: {{ fechaPreCierre }} H: {{ horaPreCierre }}
          </label>
        </div>
      </div>
      <div class="col-sm-6 pl-2">
        <div class="form-group">
          <label class="col-form-label">Glosa Cierre:</label>
          <textarea
            class="form-control"
            placeholder=""
            rows="3"
            v-model="glosaCierre"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button class="btn btn-outline-dark mr-2" @click="cancelarModal()">
          Cerrar
        </button>
        <button class="btn btn-success" @click="confirmarModal()">
          Confirmar
        </button>
      </div>
    </div>
    <div class="row custom-card-position">
      <div class="col-sm-9 offset-sm-1 pl-5">
        <b-card no-body class="bg-warning text-center py-2">
          Advertencia:
          <div class="col-sm-12 text-center">
            Una vez que confirma, ya no se podra abrir y se podra recién facturar en ventas.
          </div>
        </b-card>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
import moment from 'moment';
import VueNumeric from 'vue-numeric';
import { mapState } from 'vuex';

export default {
  name: 'ModalCierre',
  props: {
    preCierreData: {
      type: Object,
      requierd: true,
      default: Object,
    },
    cierreData: {
      type: Object,
      requierd: true,
      default: Object,
    },
    preCierre: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueNumeric,
  },
  data() {
    return {
      total: 0,
      anticipos: 0,
      saldos: 0,
      glosaPreCierre: null,
      shoModalCierre: false,
      fecha: moment().format('DD/MM/YYYY'),
      hora: null,
      usuario: null,
      glosaCierre: null,
      btnCierre: 'btn btn-danger',
      fechaPreCierre: moment().format('DD/MM/YYYY'),
      horaPreCierre: null,
    };
  },
  created() {
    this.cargarModal();
    if (!this.cierreData.estado) {
      this.glosaCierre = null;
    }
  },
  methods: {
    cancelarModal() {
      this.$bvModal.hide('modal-cierre');
    },
    confirmarModal() {
      // this.fecha = moment(this.fecha).format('DD/MM/YYYY');
      this.hora = moment().format('HH:mm:ss');
      // this.usuario = this.user.name;
      this.btnCierre = 'btn btn-success';
      const DATA = {
        fecha: this.fecha,
        hora: moment().format('HH:mm:ss'),
        glosa: this.glosaCierre,
        estado: true,
      };
      this.$emit('agregarCierre', DATA);
      this.$bvModal.hide('modal-cierre');
    },
    openModalCierre() {
      this.cargarModal();
      this.shoModalCierre = true;
    },
    cargarModal() {
      this.total = this.preCierreData.total_precierre;
      this.anticipos = this.preCierreData.anticipo_precierre;
      this.saldos = this.preCierreData.saldo_precierre;
      this.glosaPreCierre = this.preCierreData.glosa_precierre;
      this.fechaPreCierre = this.preCierreData.fecha;
      this.horaPreCierre = this.preCierreData.hora;
      this.usuario = (this.preCierreData.usuario)
        ? this.preCierreData.usuario : this.user.name;
      // cargar dato del cierre
      this.glosaCierre = this.cierreData.glosa;
      this.hora = this.cierreData.hora;
      // this.fecha = moment(this.fecha).format('DD/MM/YYYY');
    },
  },
  computed: {
    ...mapState('main', [
      'user',
    ]),
  },
};
</script>

<style scoped>
.custom-card-position{
  position:absolute;
  width:100%;
  transform: translateY(40px);
}
</style>
