<template>
  <div>
    <button
      type="button"
      class="_btn btn btn-block"
      :class="factura_saved ? 'btn-success' : 'btn-danger'"
      :disabled="disabled"
      @click="openModal">
      {{ title }}
    </button>
    <b-modal
      v-model="modalFactura"
      title="Ajuste Contable"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      body-class="p-0">
      <template slot="default">
        <ul class="nav nav-tabs profile-nav mt-1 ml-1" role="tablist">
          <li class="nav-item">
            <a class="nav-link active"
              id="factura-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="factura" aria-selected="true"
              ><span class="font-weight-bold">Factura</span></a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade active show"
            id="factura" role="tabpanel"
            aria-labelledby="factura-tab">
            <div class="tab-titulo text-right pr-2">
              <span class="font-weight-bold">Datos de Factura</span>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label for="dia" class="mb-0">Dia</label>
                <input type="text"
                  class="form-control"
                  placeholder="Ingrese Dia"
                  id="dia" v-model.trim="factura_dia"/>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6">
                <label for="nit" class="mb-0">Nit</label>
                <input type="text"
                  class="form-control"
                  placeholder="Ingrese Nit"
                  id="nit" @focusout="setFocus"
                  v-model.trim="factura.nit"/>
              </div>
              <div class="col-sm-6">
                <label for="tipo" class="mb-0">Tipo</label>
                <multiselect id="tipo"
                  v-model="tipo_factura_compra"
                  track-by="nombre" label="nombre"
                  placeholder="Tipo Factura"
                  :options="tipos_factura"
                  select-label="" deselect-label="X"
                  style="height: 25px"
                  :allow-empty="false"
                  :searchable="true">
                </multiselect>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12">
                <label for="razon_social" class="mb-0">Razon Social</label>
                <input type="text"
                  class="form-control"
                  placeholder="Ingrese Razon Social"
                  id="razon_social"
                  v-model.trim="factura.nombre"/>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <label for="nro_factura" class="mb-0"></label>
                <multiselect
                  v-model="tipo_numero_factura"
                  track-by="nombre" label="nombre"
                  placeholder="Tipo"
                  :options="tipos_numero_factura"
                  :allow-empty="false"
                  select-label="" deselect-label="X"
                  :searchable="true">
                </multiselect>
              </div>
              <div class="col-sm-3 pl-0">
                <label for="nro_factura" class="mb-0 pl-1">N°</label>
                <input type="text"
                  class="form-control"
                  ref="numero"
                  placeholder="N°"
                  id="nro_factura"
                  v-model.trim="factura.numero"/>
              </div>
              <div class="col-sm-6">
                <label for="nro_autorizacion" class="mb-0"
                  >N° de Autorizacion</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ingrese Numero de Autorizacion"
                  id="nro_autorizacion"
                  v-model.trim="factura.numero_autorizacion"
                />
              </div>
            </div>
            <div class="form-group row mt-4">
              <label
                for="importe_total"
                class="col-sm-5 col-form-label text-right pr-1"
                >Imp. Total:</label
              >
              <div class="col-sm-5 pl-1">
                <div class="div-input" id="importe_total">
                  {{ importeTotal | numeral("0,0.00") }}
                </div>
              </div>
            </div>
            <div class="form-group row mt-2">
              <label
                for="sujeto_cf"
                class="col-sm-5 col-form-label text-right pr-1"
                >No Sujeto a CF:</label
              >
              <div class="col-sm-5 pl-1">
                <vue-numeric
                  class="form-control"
                  id="sujeto_cf"
                  separator=","
                  :read-only="isNoSujetoCFDisabled"
                  read-only-class="div-input"
                  v-model="factura.sujeto_cf"
                  v-bind:precision="2"
                />
              </div>
            </div>
            <div class="form-group row mt-2">
              <label
                for="descuentor"
                class="col-sm-5 col-form-label text-right pr-1"
                >Desc. y Rebaja:</label
              >
              <div class="col-sm-5 pl-1">
                <!--<div class="div-input">{{ factura.descuento | numeral('0,0.00') }}</div>-->
                <vue-numeric
                  class="form-control"
                  id="descuento"
                  separator=","
                  v-model="factura.descuento"
                  :read-only="isDescuentoDisabled"
                  read-only-class="div-input"
                  v-bind:precision="2"
                />
              </div>
            </div>
            <!--<div class="form-group row mt-2">
                  <label class="col-sm-5 col-form-label text-right pr-1">Imp. Neto:</label>
                  <div class="col-sm-5 pl-1">
                      <div class="div-input">{{ importeNeto | numeral('0,0.00') }}</div>
                  </div>
                </div>-->
            <div class="form-group row mt-2">
              <label for="iva" class="col-sm-5 col-form-label text-right pr-1"
                >{{ "IVA (" + porcentaje + "%)" }}:</label
              >
              <div class="col-sm-5 pl-1">
                <!--<input type="text" class="form-control" id="iva"
                  v-model="factura.impuesto">-->
                <!--<vue-numeric class="form-control" separator=","
                  v-model="factura.impuesto" v-bind:precision="2"/>-->
                <div class="div-input">{{ impuesto | numeral("0,0.00") }}</div>
              </div>
            </div>
            <div class="form-group row mt-2">
              <label
                for="codigo_control2"
                class="col-sm-5 col-form-label text-right pr-1"
                >Codigo de Control:</label
              >
              <div class="col-sm-5 pl-1">
                <input
                  type="text"
                  class="form-control"
                  id="codigo_control2"
                  v-model="factura.codigo_control"
                  @keypress="esCodigoControl"
                />
              </div>
            </div>
            <div class="form-group row mt-4">
              <div class="col-sm-6 offset-sm-4" style="display: inline">
                <button
                  class="btn btn-secondary"
                  style="width: 90px"
                  @click="hideModal">Cerrar</button>
                <button
                  class="btn btn-primary"
                  style="width: 90px"
                  @click="saveFactura"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from '@/modules/common/axiosERP';
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import { round } from 'mathjs';
import moment from 'moment';
// import { validate } from '../../utils/validate';
import { getNumberFromString } from '../../utils/helpers';
import { util } from '../../plugins/util';

export const MODE = { FACTURA: 1, RETENCION: 2, AMBOS: 3 };
const TIPO_NUMERO_FACTURA = 1;
const TIPO_NUMERO_DUI = 2;

export default {
  name: 'factura',
  props: {
    subtotal: {
      type: Number,
    },
    descuentoEditable: {
      type: Boolean,
      default: false,
    },
    noSujetoCreditoFiscalEditable: {
      type: Boolean,
      default: true,
    },
    descuento: {
      default: 0,
    },
    tipos_factura: {
      type: Array,
      default() {
        return [];
      },
    },
    load: {
      type: Object,
      default: null,
    },
    fecha: {
      default: null,
    },
    cuentaImpuesto: {
      default: null,
    },
    nit: {
      default: null,
    },
    numeroComprobante: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInMonedaPrincipal: {
      type: Boolean,
      default: true,
    },
    tipoCambio: {
      default: 1,
    },
    shortTitle: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.setData();
  },
  data: () => ({
    modalFactura: false,
    tipo_retencion: null,
    totalVentaComputado: 0,
    factura_dia: '',
    factura: {
      fecha: '',
      nit: '',
      nombre: '',
      numero: '',
      numero_autorizacion: '',
      sujeto_cf: 0,
      descuento: 0,
      impuesto: '',
      codigo_control: '',
      tipo_numero_factura_id: null,
      tipo_factura_compra_id: null,
    },
    retencion_dia: '',
    tipos_numero_factura: [
      { id: TIPO_NUMERO_FACTURA, nombre: 'Factura' },
      { id: TIPO_NUMERO_DUI, nombre: 'DUI' },
    ],
    tipo_numero_factura: null,
    tipo_factura_compra: null,
    factura_saved: false,
  }),
  methods: {
    setData() {
      if (!this.load) {
        if (this.tipos_factura.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.tipo_factura_compra = this.tipos_factura[0];
        }
        if (this.fecha) {
          this.factura_dia = moment(this.fecha).format('D');
        }
        // eslint-disable-next-line prefer-destructuring
        this.tipo_numero_factura = this.tipos_numero_factura[0];
      } else {
        // this.tipo_numero_factura = load(this.tipos_numero_factura, this.load.tipo_numero_id);
        this.tipo_numero_factura = this.tipos_numero_factura.find(
          (ele) => ele.id === this.load.tipo_numero_id,
        );
        // this.tipo_factura_compra = load(this.tipos_factura, this.load.tipo_compra_id);
        this.tipo_factura_compra = this.tipos_factura.find(
          (ele) => ele.id === this.load.tipo_compra_id,
        );
        this.factura_dia = moment(this.load.fecha).format('D');
        this.factura.nit = this.load.nit;
        this.factura.nombre = this.load.nombre;
        this.factura.numero = this.load.numero;
        this.factura.numero_autorizacion = this.load.numero_autorizacion;
        this.factura.sujeto_cf = this.load.sujeto_cf;
        this.factura.codigo_control = this.load.codigo_control;
        this.factura.descuento = this.load.descuento;
        this.factura_saved = true;
        this.saveFactura();
      }
    },
    openModal() {
      this.modalFactura = true;
      if (!this.load) {
        if (this.numeroComprobante) {
          this.factura.numero = getNumberFromString(this.numeroComprobante);
        }
      }
    },
    hideModal() {
      if (this.errors().length > 0) {
        this.factura_saved = false;
      }
      this.modalFactura = false;
    },
    getDataByNit() {
      if (this.factura.nit) {
        this.factura.nombre = '';
        this.factura.numero_autorizacion = '';
        axios.get(`/factura/data-by-nit/${this.factura.nit}`)
          .then((response) => {
            // console.log(response.data);
            const data = response.data.factura;
            if (data) {
              this.factura.nombre = data.nombre;
              this.factura.numero_autorizacion = data.numero_autorizacion;
            }
          })
          .catch((error) => {
            util.showNotify('Hubo un error al obtener los datos!', 'error');
            util.showNotify(error.response.data.message, 'error');
          });
      }
    },
    esCodigoControl(evt) {
      const charCode = evt.keyCode;
      const keyValue = evt.key.toUpperCase();
      if ((charCode >= 48 && charCode <= 57)
        || (charCode >= 65 && charCode <= 90)
        || (charCode >= 97 && charCode <= 122)) {
        if (this.factura.codigo_control.length >= 14) {
          this.factura.codigo_control = this.factura.codigo_control.slice(0, 13);
          this.factura.codigo_control += keyValue;
        } else {
          this.factura.codigo_control += keyValue;
          let codigo = this.factura.codigo_control;
          if ((codigo.length % 3) === 0) {
            if (codigo.charAt(codigo.length - 1) !== '-') {
              codigo = `${codigo.slice(0, codigo.length - 1)}-${codigo.charAt(codigo.length - 1)}`;
            }
          }
          this.factura.codigo_control = codigo;
        }
      }
      evt.preventDefault();
    },
    setFocus() {
      // console.log('viene al focus');
      this.getDataByNit();
      this.$refs.numero.focus();
    },
    saveFactura() {
      if (this.validar()) {
        this.factura_saved = true;
        this.modalFactura = false;
        const factura = this.getData();
        this.$emit('factura', factura);
      }
    },
    validar() {
      const errors = this.errors();
      if (errors.length > 0) {
        errors.forEach((error) => {
          util.showNotify(error, 'warn');
        });
        return false;
      }
      if (!this.cuentaImpuesto) {
        util.showNotify('Imposible agregar, no tiene cuenta para Credito Fiscal!', 'error');
        return false;
      }
      return true;
    },
    errors() {
      const errors = [];
      if (!this.factura_dia) {
        errors.push('Ingrese Dia!');
      }
      if (!this.factura.nit) {
        errors.push('Ingrese Nit!');
      }
      if (!this.factura.nombre) {
        errors.push('Ingrese Nombre!');
      }
      if (!this.tipo_factura_compra) {
        errors.push('Seleccione Tipo Compra!');
      }
      if (!this.factura.numero) {
        errors.push('Ingrese Numero!');
      }
      if (!this.factura.numero_autorizacion) {
        errors.push('Ingrese Numero de Autorizacion!');
      }
      if (this.importeNeto <= 0) {
        errors.push('Importe debe ser mayor a 0!');
      }
      if (!this.isValidoCodigoControl()) {
        errors.push('El codigo de control tiene un formato incorrecto!');
      }
      if (this.tipo_numero_factura.id === 1) {
        if (!this.isNumber(this.factura.numero)) {
          errors.push('Numero de Factura Incorrecto!');
        }
      }
      return errors;
    },
    isValidoCodigoControl() {
      if (this.factura.codigo_control) {
        const codigo = this.factura.codigo_control.trim();
        for (let i = 0; i < codigo.length; i += 3) {
          if ((i + 1) === codigo.length) {
            return false;
          }
        }
      }
      return true;
    },
    getData() {
      if (this.factura_saved) {
        this.factura.fecha = `${moment(this.fecha).format('YYYY-MM')}-${this.factura_dia}`;
        this.factura.dia = this.factura_dia;
        this.factura.tipo_factura_compra_id = this.tipo_factura_compra.id;
        this.factura.tipo_numero_factura_id = this.tipo_numero_factura.id;
        this.factura.impuesto = this.impuesto;
        this.factura.importe_total = this.importeTotal;
        return this.factura;
      }
      return null;
    },
    isNumber(value) {
      const expresion = /^\d+$/;
      return expresion.test(value);
    },
  },
  watch: {
    /* eslint func-names: ["error", "never"] */
    factura_dia(new1) {
      if (new1 !== '') {
        const last = moment(this.fecha).endOf('month').format('D');
        if (parseInt(new1, 10) < 1 || parseInt(new1, 10) > last) {
          this.factura_dia = '';
        }
      }
    },
    retencion_dia(new1) {
      if (new1 !== '') {
        const last = this.$moment().endOf('month').format('D');
        if (parseInt(new1, 10) < 1 || parseInt(new1, 10) > last) {
          this.retencion_dia = '';
        }
      }
    },
    'factura.numero': function (new1, old) {
      if (new1) {
        if (this.tipo_numero_factura.id === 1) {
          if (!this.isNumber(new1)) {
            this.factura.numero = old;
          }
        }
      }
    },
    'factura.numero_autorizacion': function (new1) {
      if (new1) {
        if (!this.isNumber(new1)) {
          this.factura.numero_autorizacion = new1;
        }
      }
    },
    tipo_numero_factura(new1) {
      if (new1) {
        if (new1.id === TIPO_NUMERO_DUI) {
          this.factura.sujeto_cf = 0;
          this.factura.descuento = 0;
        }
      }
    },
    fecha(new1) {
      if (new1 && !this.load && !this.factura_saved) {
        this.factura_dia = moment(new1).format('D');
      }
    },
    nit(new1) {
      if (new1) {
        this.factura.nit = new1;
        this.getDataByNit();
      }
    },
    descuento(new1) {
      if (new1 === '') {
        this.factura.descuento = 0;
        return;
      }
      this.factura.descuento = new1;
    },
    factura_saved(new1) {
      this.$emit('factura-saved', new1);
    },
  },
  computed: {
    importeNeto() {
      return this.importeTotal - (parseFloat(this.factura.sujeto_cf)
         + parseFloat(this.factura.descuento));
    },
    impuesto() {
      return (
        (this.importeTotal - parseFloat(this.factura.sujeto_cf))
          * (parseFloat(this.porcentaje) / 100)
      );
    },
    porcentaje() {
      if (this.cuentaImpuesto) {
        return parseFloat(this.cuentaImpuesto.porcentaje);
      }
      return 13;
    },
    importeTotal() {
      // eslint-disable-next-line prefer-const
      let subtotal = parseFloat(this.subtotal);
      if (this.tipo_numero_factura.id === TIPO_NUMERO_FACTURA) {
        return this.isInMonedaPrincipal ? subtotal
          : round(subtotal * parseFloat(this.tipoCambio), 2);
      }
      return subtotal / (this.porcentaje / 100);
    },
    isNoSujetoCFDisabled() {
      if (this.tipo_numero_factura.id === TIPO_NUMERO_DUI) {
        return true;
      }
      return !this.noSujetoCreditoFiscalEditable;
    },
    isDescuentoDisabled() {
      if (this.tipo_numero_factura.id === TIPO_NUMERO_DUI) {
        return true;
      }
      return !this.descuentoEditable;
    },
    title() {
      return this.shortTitle ? 'F.' : 'Factura';
    },
  },
  components: {
    VueNumeric,
    Multiselect,
  },
};
</script>

<style scoped>
  .div-input {
    outline: initial !important;
    border: 1px solid #ced4da;
    color: #474d6d;
    background-color: rgb(238, 238, 238);
    opacity: 1;
    display: block;
    width: 100%;
    height: calc(1.9695rem + 2px);
    padding: .375rem .75rem;
    font-size: .813rem;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  ._btn {
    padding: .4rem 1.25rem;
  }
</style>
